/* index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #303030;
  background-image: url('./assets/images/background/ai_brain.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;
}

* {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  body {
    background-color: #303030;
    background-image: url('./assets/images/background/01_mobile_wallpaper.jpg');
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}