.about-me-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  margin-top: 2.5%;
}

.about-me-container {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 100%;
  margin: 40px auto;
  border: 1px solid #ddd;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  padding: 20px;
}

.image-container {
  flex: 0 0 250px;
  margin-right: 20px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.side-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.side-text-content {
  flex: 1;
  line-height: 1.8;
}

.side-text-container h1 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 10px;
  font-style: italic;
  font-family:'Courier New', Courier, monospace;
}

.side-text-content p {
  font-size: 1.1rem;
  text-align: left;
}

.full-width-text-container {
  width: 100%;
}

.full-width-text-container p {
  margin-bottom: 20px;
  font-size: 1.1rem;
  text-align: left;
  line-height: 1.8;
}

a {
  color: #00bfff; /* Bright color for the link to stand out */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold link text */
}

a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}

a:active {
  color: #923fff; /* Different color for visited links */
}

/* Responsive Design */

@media (max-width: 768px) {
  .about-me-wrapper {
    padding: 10px;
  }

  .about-me-container {
    padding: 15px;
  }

  .side-text-container {
    flex-direction: column; /* Stack the image and text vertically */
    align-items: center; /* Center the content */
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
    width: 80%; /* Reduce the size of the image */
  }

  .image-container img {
    width: 100%; /* Ensure the image is responsive */
    height: auto;
  }

  .side-text-content {
    text-align: center; /* Center the text below the image */
  }

  .side-text-container h1 {
    font-size: 2rem;
  }

  .side-text-content p {
    font-size: 1rem;
  }

  .full-width-text-container p {
    font-size: 1rem;
    margin-bottom: 15px;
  }
}