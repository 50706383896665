/* ------------------------------------- Base Style for the Navbar ------------------------------------- */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color as needed */
  z-index: 1000;
  transition: top 0.5s ease-in-out; /* Smooth transition for the top property */
}

/* Class to show the navbar */
.navbar-show {
  top: 0;
}

/* Class to hide the navbar */
.navbar-hide {
  top: -100px; /* Move navbar off screen */
}

/* Flex container to help center the links */
.navbar-container {
  display: flex;
  justify-content: center; /* space-between (when we add sign up and sign in buttons) */
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
}

/* ------------------------------------- LOGO ------------------------------------- */

.navbar-logo {
  position: absolute; /* Absolute positioning to keep it on the left */ /* flex (when we add sign up and sign in buttons) */
  left: 2rem; /* Align the logo with the padding of the navbar */
  margin-right: auto;
  align-items: center;
  display: inline-block;
  height: 50px;
}

.navbar-logo img {
  height: 50px;
}

/* ------------------------------------- Central Navbar Links ------------------------------------- */

/* Center the container */
.rainbow-container {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex: 1; /* This ensures the links stay centered */
}

/* Center the links */
.navbar-links {
  display: flex;
  justify-content: center; /* Center the links within their container */
  align-items: center;
}

/* Glowing button styles */
.navbar-links a, .navbar-links .link {
  color: white;
  margin: 0 0.75rem;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.navbar-links a::after, .navbar-links .link::-webkit-textfield-decoration-container {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  left: 0;
  top: 0;
  border-radius: 30px;
}

.navbar-links a::before, .navbar-links .link::before{
  content: "";
  background: linear-gradient(
    45deg,
    #FF0000, #FF7300, #FFFB00, #48FF00,
    #00FFD5, #002BFF, #FF00C8, #FF0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height:  calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

/* Glowing Effect Animation */
@keyframes glowing {
  0% {background-position: 0 0;}
  50% {background-position: 400% 0;}
  100% {background-position: 0 0;}
}

/* hover */
.navbar-links a:hover::before, .navbar-links .link:hover::before{
  opacity: 1;
}

.navbar-links a:active:after, .navbar-links .link:active:after {
  background: transparent;
}

.navbar-links a:active, .navbar-links .link:active {
  color: #000;
  font-weight: bold;
}

.menu-icon {
  display: none;
  cursor: pointer;
  color: white;
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.5); /* Initial red glow */
  animation: rainbow-glow 3s infinite alternate; /* Apply the rainbow glow animation */
}

.menu-icon img {
  width: 100%;
  height: 100%;
}

.menu-icon:hover {
opacity: 1; /* Increase opacity on hover */
background-color: rgba(0, 0, 0, 0.7); /* Slightly darker background on hover */
}

.menu-icon:active {
background-color: rgba(0, 0, 0, 0.8); /* Even darker background on active */
}

/* Rainbow Glow Animation */
@keyframes rainbow-glow {
0% { box-shadow: 0 0 15px rgba(255, 0, 0, 0.7), 0 0 30px rgba(255, 0, 0, 0.7); }   /* Red */
20% { box-shadow: 0 0 15px rgba(255, 127, 0, 0.7), 0 0 30px rgba(255, 127, 0, 0.7); } /* Orange */
40% { box-shadow: 0 0 15px rgba(255, 255, 0, 0.7), 0 0 30px rgba(255, 255, 0, 0.7); } /* Yellow */
60% { box-shadow: 0 0 15px rgba(0, 255, 0, 0.7), 0 0 30px rgba(0, 255, 0, 0.7); }   /* Green */
80% { box-shadow: 0 0 15px rgba(0, 0, 255, 0.7), 0 0 30px rgba(0, 0, 255, 0.7); }   /* Blue */
100% { box-shadow: 0 0 15px rgba(75, 0, 130, 0.7), 0 0 30px rgba(75, 0, 130, 0.7); } /* Indigo */
}

/* ------------------------------------- LANGUAGE BUTTONS - RIGHT SIDE NAVBAR ------------------------------------- */

.language-switcher {
  display: flex;
  gap: 0;
  align-items: center;
  position: absolute;
  right: 1rem; /* Position it to the right side */
}

.language-switcher button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 2px solid transparent;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-switcher button.active {
  border-color: white;
  background-color: white;
  color: black;
  font-weight: 500;
}

.language-switcher button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* ------------------------------------- Responsive Style ------------------------------------- */

@media (max-width: 1200px) {
  .menu-icon {
    display: block; /* Ensure it shows */
    width: 50px;
    height: 50px;
    margin-left: auto;
    color: white; /* Change this to your desired color */
  }

  .navbar-container {
    width: 100%;
  }

  .navbar-links {
    display: flex; /* Change this to flex to keep it structured */
    position: absolute;
    top: 65px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    transform: translateY(-60px); /* Start slightly above the view */
    opacity: 0; /* Start invisible */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transition for transform and opacity */
    pointer-events: none; /* Disable pointer events when not active */
  }

  .navbar-links.active {
    display: flex;
    transform: translateY(+20px); /* Move to original position */
    opacity: 1;
    pointer-events: auto; /* Enable pointer events when active */
    left: 0;
  }

  .navbar-links.closing {
    transform: translateY(-60px); /* Move back up for hiding animation */
    opacity: 0;
    pointer-events: none;
  }

  .navbar-links a {
    font-size: 1.5rem;
    padding: 10px;
    margin: 5px 0;
    width: 100%;
    text-align: center;
  }

  .navbar-logo {
    position: absolute; /* Absolute positioning to keep it on the left */ /* flex (when we add sign up and sign in buttons) */
    left: 1rem; /* Align the logo with the padding of the navbar */
    margin-right: auto;
    align-items: center;
    justify-content: center;
    display: inline-block;
  }

  .language-switcher {
    position: static;
    margin-top: 20px; /* Add some space above it */
  }
  
}

@media (max-width: 768px) {
  .menu-icon {
    display: block; /* Also ensure it shows in smaller devices */
  }
  .navbar-logo img {
    height: 30px; /* Further reduction for mobile */
  }
  .navbar-logo {
    display: flex;
    align-items: center;
  }

}

@media (max-width: 480px) {
  .navbar-logo img {
    height: 35px; /* Even smaller for very small screens */
  }

  .navbar-links a {
    font-size: 1.2rem;
  }

}