.home {
    margin-top: 90px; /* Add padding to prevent content overlap with the navbar */
}

.home h1 {
    color: #00FFFF; /* Neon-like color reminiscent of tech and AI */
    font-family: 'Orbitron', sans-serif; /* Futuristic font style */
    font-size: 40px;
    margin-left: 40px;
    margin-bottom: 50px;
    margin-top: 70px;
    letter-spacing: 4px;
    text-transform: uppercase;
    position: relative;
    text-shadow: 
        0 0 5px #00CED1, 
        0 0 10px #00CED1, 
        0 0 20px #7F00FF,  /* Purple */
        0 0 40px #7F00FF, 
        0 0 80px #7F00FF; /* Glowing effect */
    animation: pulse 2s infinite; /* Continuous pulsating effect */
}

.home h1::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background: linear-gradient(120deg, transparent, rgba(0, 255, 255, 0.2), transparent);
    z-index: -1;
    border-radius: 10px;
    filter: blur(15px);
}

.home h1::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(0, 255, 255, 0.5), transparent 70%);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    filter: blur(25px);
    z-index: -1;
}

@keyframes pulse {
    0%, 100% {
        text-shadow: 
            0 0 5px #00aed1, 
            0 0 10px #00aed1, 
            0 0 20px #7F00FF, 
            0 0 40px #7F00FF, 
            0 0 80px #7F00FF;
    }
    50% {
        text-shadow: 
            0 0 10px #00aed1, 
            0 0 20px #00aed1, 
            0 0 40px #7F00FF, 
            0 0 80px #7F00FF, 
            0 0 160px #7F00FF;
    }
}

.back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
    opacity: 0.8;
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.5); /* Initial red glow */
    animation: rainbow-glow 3s infinite alternate; /* Apply the rainbow glow animation */
}

.back-to-top img {
    width: 20px;
    height: 20px;
    rotate: -90deg;
}

.back-to-top:hover {
    opacity: 1; /* Increase opacity on hover */
    background-color: rgba(0, 0, 0, 0.7); /* Slightly darker background on hover */
}

.back-to-top:active {
    background-color: rgba(0, 0, 0, 0.8); /* Even darker background on active */
}

/* Rainbow Glow Animation */
@keyframes rainbow-glow {
    0% { box-shadow: 0 0 15px rgba(255, 0, 0, 0.7), 0 0 30px rgba(255, 0, 0, 0.7); }   /* Red */
    20% { box-shadow: 0 0 15px rgba(255, 127, 0, 0.7), 0 0 30px rgba(255, 127, 0, 0.7); } /* Orange */
    40% { box-shadow: 0 0 15px rgba(255, 255, 0, 0.7), 0 0 30px rgba(255, 255, 0, 0.7); } /* Yellow */
    60% { box-shadow: 0 0 15px rgba(0, 255, 0, 0.7), 0 0 30px rgba(0, 255, 0, 0.7); }   /* Green */
    80% { box-shadow: 0 0 15px rgba(0, 0, 255, 0.7), 0 0 30px rgba(0, 0, 255, 0.7); }   /* Blue */
    100% { box-shadow: 0 0 15px rgba(75, 0, 130, 0.7), 0 0 30px rgba(75, 0, 130, 0.7); } /* Indigo */
}


.show-more {
    display: block; /* Allows margin auto to center the button */
    text-align: center; /* Ensures the text inside the button is centered */
    margin: 20px auto; /* Centers the button horizontally */
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    color: white;
    background-color: rgba(0, 60, 255, 0.7);
    border: 2px solid transparent;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
}

.show-more .arrow-icon {
    margin-left: 8px;
    width: 18px;
    height: auto;
    display: inline-block;
    vertical-align: middle - 3px;
    rotate: 90deg;
}

.show-more:hover{
    background-color: rgba(89, 0, 255, 0.7);
}
  
/* Hover effect for the arrow */
.show-more:hover .arrow-icon {
    transform: translateX(5px); /* Move the arrow slightly to the right on hover */
    transition: transform 0.3s ease-in-out;
}

/* ------------------------------------- Responsive Style ------------------------------------- */

@media (max-width: 1200px) {
    .home h1{
        font-size: 30px;
        margin: 20px 0 20px 20px;
    }

    .show-more {
        font-size: 16px;
    }

    .show-more .arrow-icon {
        width: 12px;
    }

    .back-to-top {
        width: 40px;
        height: 40px;
        right: 30px;
    }
}

@media (max-width: 768px) {
    .show-more {
      padding: 8px 15px; /* Reduce padding */
      font-size: 16px; /* Reduce font size */
    }
  
    .show-more .arrow-icon {
      width: 14px; /* Reduce the size of the arrow */
    }

    .show-more:hover{
        background-color: rgba(89, 0, 255, 0.7);
    }

    .show-more:active {
        background-color: rgba(89, 0, 255, 0.7);
    }

    .back-to-top {
        width: 30px;
        height: 30px;
        right: 20px;
    }

    .back-to-top img {
        width: 15px;
        height: 15px;
    }
      
}

@media (max-width: 414px) {
    .home h1{
        font-size: 20px;
    }
}
