.project-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px; /* Reduced space between project sections */
  background-color: rgba(0, 0, 0, 0.75);
  padding: 30px; /* Reduced padding */
  border-radius: 10px; /* Optional: rounded corners for the container */
  max-width: 80%; /* Make the container smaller in width */
  margin: 20px auto; /* Center the container horizontally */
  position: relative; 
}

.project-slideshow {
  width: 40%; /* Slideshow takes 45% of the container width */
  margin-right: 20px; /* Reduce space between slideshow and text */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
}

.project-image, .project-video {
  width: 100%; /* Ensure the media fills their respective container width */
  height: auto;
}

.custom-arrow-project-container {
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  z-index: 1;
  width: 40px; /* Reduce width */
  height: 40px; /* Reduce height */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  border-radius: 15px; /* Rounded corners */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the SVG icons inside the arrow buttons */
.custom-arrow-project-container img {
  width: 100%; /* Adjust size of the icon */
  height: 100%;
}

/* Optional: Add hover effects for better UX */
.custom-arrow-project-container:hover {
  background-color: rgba(192, 192, 192, 0.8); /* Darken background on hover */
}

/* Optional: Add hover effects for better UX */
.custom-arrow-project-container:active {
  background-color: rgb(146, 146, 146, 0.5); /* Darken background when active */
}

.project-text {
  width: 50%; /* Text takes 45% of the container width */
  padding-left: 20px; /* Space between slideshow and text */
  color: white;
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.5; /* Improve text readability */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: flex-start; /* Align items to the start of the container */
  margin-right: 20px;
}

.project-text h2 {
  font-size: 2.2rem; /* Adjust size of heading */
  margin-bottom: 30px; /* Space below the heading */
  font-weight: bold; /* Make heading bold */
    display: flex;
    align-items: center; /* Vertically centers the image and text */
    gap: 20px;
}

.project-text p {
  font-size: 1.5rem; /* Adjust size of paragraph text */
  margin-bottom: 5px; /* Space below the paragraph */
  display: flex;
  justify-content: center;
  text-align: justify;
  gap: 20px;
}

.project-text a {
  font-size: 1.5rem; /* Font size for the link */
  color: #00bfff; /* Bright color for the link to stand out */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold link text */
  margin: 20px 0; /* Space below the heading */
  display: flex;
  justify-content: center;
  gap: 20px;
}

.project-text a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}

.project-text a:visited {
  color: #1e90ff; /* Different color for visited links */
}

.text-image{
  width: 40px;
  height: auto;
}

.date p {
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: white;
  font-size: 1.2rem;
  font-style: italic;
}

.long_text-image {
  width: 40px;
  max-height: 40px;
}

.p{
  margin: 0 !important;
}

/* ------------------------------------- RESPONSIVE STYLE - MOBILE DEVICES ------------------------------------- */

@media (max-width: 1200px) {

  .text-image, .long_text-image {
    width: 30px;
    height: 30px;
  }

  .project-text h2 {
    display: flex;
    align-items: center; /* Vertically centers the image and text */
    gap: 20px;
    font-size: 1.5rem;
  }

  .project-text p, .project-text a {
    font-size: 1rem;
    text-align: left;
  }

  .date p {
    text-align: center;
    font-size: 0.8rem;
  }
  
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .project-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    padding: 20px;
  }

  .project-slideshow {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .custom-arrow-project-container {
    width: 25px; /* Further reduced arrow size on mobile */
    height: 25px;
  }

  /* Optional: Add hover effects for better UX */
  .custom-arrow-project-container:hover {
    background-color: rgba(192, 192, 192, 0.8); /* Darken background on hover */
  }

  /* Optional: Add hover effects for better UX */
  .custom-arrow-project-container:active {
    background-color: rgb(146, 146, 146, 0.5); /* Darken background when active */
  }

  .text-image, .long_text-image {
    width: 20px;
    height: 20px;
  }

  .project-text {
    width: 100%;
    text-align: center;
    margin: 0;
    padding-left: 0;
  }

  .project-text h2 {
    font-size: 1.2rem;
  }

  .project-text p, .project-text a {
    font-size: 0.8rem;
    text-align: left;
  }

  .date p {
    position: relative;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 0.7rem;
  }
}