/* Center the Privacy Policy container */
.privacy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    margin-top: 2.5%;
}

/* Styling for the content box */
.privacy-content {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    width: 100%;
    margin: 40px auto;
    border: 1px solid #ddd;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
    padding: 20px;
}

/* Header styling */
.privacy-title {
    text-align: center;
    font-size: 2.5rem;
    color: white;
    margin-bottom: 1rem;
}

.privacy-date {
    text-align: center;
    font-size: 1rem;
    color: #dedede;
    margin-bottom: 1.5rem;
}

/* Styling for headings */
h2 {
    font-size: 1.8rem;
    margin-top: 2rem;
    color: #fff;
    margin-bottom: 0.5rem;
}

h3{
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
}

h4{
    margin-left: 3rem;
    margin-bottom: 0.5rem;
}

h3, h4 {
    font-size: 1.4rem;
    margin-top: 1.5rem;
    color: #ddd;
}

/* Paragraph and list styling */
p {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.6;
    color: white;
}

ul {
    margin-left: 1.5rem;
}

ul li {
    margin-bottom: 0.5rem;
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.3;
    color: white;
}

/* Hyperlinks */
a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    color: #731dff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .privacy-container {
        padding: 10px;
    }

    .privacy-content {
        width: 100%;
        padding: 15px;
    }

    .privacy-title {
        font-size: 1.8rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3, h4 {
        font-size: 1.2rem;
    }
}
