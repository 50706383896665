.footer {
    background-color: rgba(0, 0, 0, 0.6); 
    color: #fff;
    text-align: left; /* Align text to the left */
    padding: 20px 0;
    position: relative;
    width: 100%;
    bottom: 0;
}

.footer-content {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the top */
}

.footer-left {
    display: flex;
    flex-direction: column;
}

.footer-logo-image {
    height: 50px;
    margin-bottom: 10px; /* Space between the logo and the text */
}

.footer-policy-terms {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
}

.footer-policy-terms a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 5px;
    transition: color 0.3s ease;
}

.footer-policy-terms a:hover {
    color: #1f9aff;
}

.footer-links {
    display: flex;
    margin-left: auto; /* Pushes the columns to the right */
}

.footer-links-column-1,
.footer-links-column-2 {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    margin-right: 20px; /* Space between the two columns */
    text-align: center;
}

.footer-links-column-1 {
    margin-right: 60px;
}
.footer-links-column-1 h3 {
    color: #1f9aff; 
}

.footer-links-column-2 h3 {
    color: #9900ff;
}

.footer-links-column-1 h3,
.footer-links-column-2 h3 {
    margin-bottom: 10px;
    font-size: 1.4rem;
    margin-top: 10px;
}

.footer-links-column-1 a,
.footer-links-column-2 a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 5px; /* Space between each link */
    transition: color 0.3s ease;
    margin-bottom: 5px; /* Space below the paragraph */
    display: flex;
    justify-content: center;
    gap: 10px;
}

.footer-links-column-1 a:hover {
    color: #008cff;
}

.footer-links-column-2 a:hover{
    color: #9900ff;
}

p, #copyright {
    margin: 10px 0 0; /* Reduce margin at the top and bottom */
    padding-top: auto; /* Ensures the text is pushed down if needed */
    position: relative;
    bottom: 0;
    text-align: center;
}

.linkImages {
    width: 20px;
    height: auto;
}

.footer-policy-terms {
    font-size: 0.9rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack elements vertically on small screens */
        align-items: center; /* Center items horizontally */
    }

    .footer-links {
        flex-direction: row; /* Stack links vertically on small screens */
        justify-content: space-between;
        align-items: center; /* Center links */
        width: 100%; /* Ensure full width for links */
    }

    .footer-links-column-1,
    .footer-links-column-2 {
        margin: 0; /* Remove margin for better stacking */
        width: 50%; /* Full width for links */
        text-align: center; /* Center text in links */
    }

    .footer-logo-image {
        height: 30px; /* Further reduce logo height */
    }

    .footer-links-column-1 h3,
    .footer-links-column-2 h3 {
        font-size: 1rem; /* Further reduce font size */
    }

    .footer-links-column-1 a,
    .footer-links-column-2 a {
        font-size: 0.9rem; /* Further reduce link font size */
    }

    p {
        font-size: 0.7rem; /* Further reduce footer text size */
        margin-top: 20px;
    }

    .footer-policy-terms {
        font-size: 0.8rem;
    }
}