/* Center the form container itself */
.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensures it takes up the full height of the viewport */
}
  
/* Styling for the form itself */
.contact-form {
  width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  flex-direction: column;
}
  
/* Form header styling */
.contact-form h1 {
  margin-top: 0;
  text-align: center;
  font-size: 2.5rem;
  color: white;
}
  
/* Styling for each form group (label and input/textarea) */
.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

/* Transparent background for input fields and textarea, with a border */
input, textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  background-color: transparent; /* Transparent background */
  border: 1px solid #ccc; /* Visible border */
  border-radius: 5px;
  color: white; /* Ensure text is visible on dark background */
  transition: border-color 0.3s ease;
}

input.error, textarea.error {
  border-color: #ff6b6b;
}

.error-message {
  color: #ff6b6b;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

textarea {
  min-height: 150px;
}

/* Submit button styling */
.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 0.75rem;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Same width as input fields */
  margin-top: 1rem; /* Add some spacing above the button */
}

.submit-btn:hover {
  /* background-color: #0056b3; */
  background-color: #731dff;
}

.custom-select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  font-size: 16px;
}

.custom-subject-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}


/* Responsive Design */
@media (max-width: 768px) {
  .contact-form-container {
    padding: 10px;
  }

  .contact-form {
    padding: 15px;
    max-width: 100%; /* Ensure the form takes up full width on smaller screens */
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 8px;
    font-size: 0.9rem;
  }

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}