/* Slideshow container */
.slideshow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.slideshow {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}
  
.full-width-image {
    width: 100%;
    height: auto;
    transition: transform 0.4s ease-in-out; /* Smooth transition */
    border-radius: 10px; /* Slight rounded corners */
    position: relative;
    cursor: pointer; 
}

/* Hover effect with glowing edges and slight 3D tilt */
.full-width-image:hover:not(.modal-open) {
    transform: scale(1.05); /* Add scale and 3D tilt effect */
    border-radius: 10px; /* Keep rounded corners on hover */
    cursor: pointer; /* Ensure pointer cursor on hover */
}

/* Arrows */
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Style the SVG icons inside the arrow buttons */
.custom-arrow img {
    width: 100%; /* Adjust size of the icon */
    height: 100%;
}
  
/* Position the previous arrow inside the left side of the image */
.custom-prev-arrow {
    left: 10px; /* Position inside the image */
}
  
/* Position the next arrow inside the right side of the image */
.custom-next-arrow {
    right: 10px; /* Position inside the image */
}
  
/* Optional: Add hover effects for better UX */
.custom-arrow:hover {
    background-color: rgba(192, 192, 192, 0.5); /* Darken background on hover */
}

/* Optional: Add hover effects for better UX */
.custom-arrow:active {
    background-color: rgb(146, 146, 146, 0.25); /* Darken background on hover */
}
  
/* Override slick-carousel's default arrow styles */
.slick-prev:before, .slick-next:before {
    content: ''; /* Remove default arrow icons */
}

/* Modal to display the focused image */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect for the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

/* Modal content that centers the image */
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    max-height: 60%;
    position: relative;
    top: -20px;
}

.modal-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Focused image that scales up */
.focused-image {
    max-width: 100%;
    max-height: 80%;
    border-radius: 10px;
    transition: none;
    transform: none; /* Slight scaling for effect */
}

/* Text below the focused image */
.image-text {
    color: white;
    font-size: 1.2rem;
    margin-top: 20px;
    text-align: center;
}

/* Close button for the modal */
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 1000;
    background-color: #ff5454;
    border-radius: 7px;
}

.modal-close-button img {
    width: 30px;
    height: 30px;
}

.modal-close-button:hover {
    background-color: rgb(255, 42, 42); /* Red color on hover */
}

.modal-close-button:active {
    background-color: rgb(210, 13, 13); /* Red color on hover */
}

/* Modal hover effect */
.modal:hover .focused-image {
    transform: none; /* Slight additional scale on hover */
}
  
.modal-arrows {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-prev-arrow,
.modal-next-arrow {
    z-index: 1001; /* Ensure arrows are above modal content */
    cursor: pointer;
}

/* ------------------------------------- Responsive Style ------------------------------------- */

@media (max-width: 1200px) {
    .slideshow {
        width: 70%;
        max-width: 90%;
        margin: 0 auto;
    }
    .custom-arrow {
        width: 30px;
        height: 30px;
    }
    .modal-content .image-text {
        font-size: 1rem;
    }
    .modal-close-button img{
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 950px) {
    .modal-close-button img{
        width: 20px;
        height: 20px;
    }
    .custom-arrow {
        width: 20px;
        height: 20px;
    }
    .modal-content .image-text {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .modal-close-button img{
        width: 20px;
        height: 20px;
    }
    .custom-arrow {
        width: 20px;
        height: 20px;
    }
    .modal-content .image-text {
        font-size: 0.6rem;
    }
}

@media (max-width: 500px) {
    .modal-content {
        max-width: 60%; /* Full width on small mobile */
        max-height: 100%;
        margin: auto;
    }

    .focused-image {
        max-height: 100%;
        margin: auto;
    }

    .image-text {
        font-size: 0.8rem;
        text-align: center;
    }

    .modal-close-button img {
        width: 15px;
        height: 15px;
    }
}